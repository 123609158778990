.ant-radio-button-wrapper:hover {
  color: #04bebe;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: #04bebe;
  border-color: #04bebe !important;
  background-color: #edfffd;

  &:hover {
    color: #04bebe;
    border-color: #04bebe;
  }

  &:first-child {
    border-color: #04bebe;
  }
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
  -webkit-box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  background-color: #04bebe;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover::before {
  background-color: #04bebe;
}