.embargos-historial {
  .ant-modal-body {
    padding: 0px;
    padding-bottom: 20px;

    .table {
      tr {
        th,
        td {
          &:nth-child(1) {
            width: 100px;
          }
          &:nth-child(2) {
            width: 100px;
            text-align: center;
          }
          &:nth-child(3) {
            width: 100px;
            text-align: center;
          }
          &:nth-child(4) {
            text-align: center;
          }
        }
      }
    }
  }
}
.button-export{
  float: right;
}