.liquidacion-empleado {
  .ant-modal-body {
    padding: 0px;
    padding-bottom: 20px;

    .table {
      min-height: auto !important;

      tr {
        th,
        td {
          font-size: 13px;

          &:nth-child(1) {
            text-align: center;
          }

          &:nth-child(2) {
            width: 300px;
          }

          &:nth-child(3) {
            text-align: center;
          }

          &:nth-child(4) {
            text-align: center;
          }

          &:nth-child(5) {
            text-align: center;
          }

          &:nth-child(6) {
            text-align: center;
          }

          &:nth-child(7) {
            text-align: center;
          }

          &:nth-child(8) {
            text-align: center;
          }
        }
      }
    }
  }
}
