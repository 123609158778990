.table {
  >.empty {
    width: 100%;
    min-height: calc(100vh - 220px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #999;
    padding-bottom: 80px;

    >img {
      width: 80px;
      display: block;
      margin: 0 auto 10px auto;
    }
  }

  >.loading {
    width: 100%;
    min-height: calc(100vh - 220px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #999;
    padding-bottom: 80px;

    >.ant-spin {
      margin-bottom: 15px;
    }
  }

  >.footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0px 0px 10px;

    >.count {
      font-size: 0.8rem;
    }
  }

  .column-sort {
    cursor: pointer;
    transition: all 0.3s ease-in-out;

    &:hover {
      background-color: #f5f5f5;
      font-weight: 600;
    }

    &.active {
      color: #04bebe;
      font-weight: 600;
    }
  }
}

.table-wrapper {
  display: block;
  overflow-x: auto;
  white-space: nowrap;
  margin-top: 10px;
}

.table-small {
  >thead {
    >tr {
      >th {
        font-size: 14px;
      }
    }
  }

  >tbody {
    >tr {
      >td {
        font-size: 13px;
      }
    }
  }
}

table {
  min-width: 100%;

  >thead {
    >tr {
      >th {
        padding: 12px;
        font-size: 16px;
        font-weight: 500;

        &.table-title {
          text-align: center;
          border-radius: 5px 5px 0px 0px;
          background: linear-gradient(118deg, #04bebe, #04bebe) !important;
          color: #fff;
        }
      }
    }
  }

  >tbody {
    >tr {
      &:first-of-type {
        border-top: 2px solid #f1f1f1;
      }

      &:nth-child(odd) {
        background-color: #fafafa;
      }

      &:hover {
        background-color: #eaf7f5;
      }

      >td {
        padding: 12px;

        >a {
          font-weight: 500 !important;

          &:hover {
            color: #04bebe !important;
          }
        }
      }
    }
  }
}

.table-empty {
  text-align: center;
}

.table-actions {
  width: 60px;

  >div {
    cursor: pointer;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 25px;
  }
}

.table-actions-menu {
  >.ant-dropdown-menu-item {
    min-width: 130px;

    svg {
      font-size: 13px;
      margin-right: 10px;
    }
  }
}

.table-footer-actions {
  text-align: center;
  padding: 30px;
}

@media only screen and (max-width: 600px) {
  .table {
    >.footer {
      display: block;
      text-align: center;
      padding: 0px 20px;

      >.count {
        margin-bottom: 20px;
      }
    }
  }
}

.rowTable {
  height: 30px;
}