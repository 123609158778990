.navbar {
  color: #626262;
  background-color: #fff;
  padding: 0 10px;
  border-radius: 5px;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: space-between;

  >.navbar-toggle {
    padding: 20px 10px 15px 10px;
    cursor: pointer;

    &:hover {
      color: #04bebe;
    }

    svg {
      font-size: 1.5em;
    }
  }

  >.user {
    padding: 10px 0;
    display: flex;
    align-items: center;
    text-align: right;
    cursor: pointer;

    >.data {
      >.name {
        margin-bottom: 0;
        font-weight: 600;
      }

      >.status {
        margin-bottom: 0;
        font-size: 0.75rem;
      }
    }

    >.icon {
      height: 40px;
      border-radius: 100px;
      margin: 0 5px 0 10px;
      background: #fcfefe;
      padding: 5px;
    }
  }
}

.navbar-actions {

  .ant-dropdown-menu-item,
  .ant-dropdown-menu-submenu-title {
    color: #626262;
    display: flex;
    align-items: center;

    &:hover {
      background-color: #f1f1f1;
    }

    >svg {
      font-size: 0.7em;
      margin-right: 10px;
    }
  }
}