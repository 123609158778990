.section-header {
  padding: 30px 40px 0px 30px;

  .section-title {
    font-size: 20px;
    font-weight: 500;

    svg {
      margin-left: 10px;
    }
  }

  .section-subtitle {
    font-size: 10px;
    font-style: italic;
    color: #888;
  }
}
