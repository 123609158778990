.organigrama {
  > .form-organigrama {
    padding: 0 10px;
  }

  > .orgchart-container {
    border: none !important;
    background-color: transparent !important;
    height: initial;

    > .orgchart {
      background-image: none !important;
    }
  }
}

.form .form-actions {
  padding: 0px !important;
}
