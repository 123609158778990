.liquidacion {
  .table-section {
    position: relative;
  }

  .warning {
    background-color: #fff0e1;
    color: #ff9f43;
    font-weight: 600;
  }

  .loading {
    width: 100%;
    min-height: calc(100vh - 180px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #999;
    padding-bottom: 80px;

    > svg {
      margin: 0 auto 30px auto;
    }
  }

  .calculating {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: 500;
    z-index: 3;

    > img {
      height: 100px;
      margin-bottom: 10px;
    }
  }

  .table-footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px 40px 20px 40px;
    min-height: 45px;
  }
}
