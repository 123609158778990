.liquidacion-historial {
  min-height: auto !important;

  tr {
    th,
    td {
      &:nth-child(1) {
        width: 150px;
        text-align: center;
      }
      &:nth-child(2) {
        width: 120px;
        text-align: center;
      }
      &:nth-child(3) {
        width: 120px;
        text-align: center;
      }
      &:nth-child(4) {
        text-align: center;
      }
    }
  }
}
