.menu-card {
  background-color: #fff;
  box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  text-align: center;
  color: #000;
  transition: all 0.3s ease-in-out;
  min-height: 165px;
  max-height: 165px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  position: relative;
  z-index: 0;

  &:hover {
    box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.25);
  }

  .icon {
    font-size: 2em;
    border-radius: 100px;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
  }

  .count {
    font-weight: 600;
    font-size: 1.74rem;
    margin-top: 10px;
  }

  .title {
    color: #626262;
  }

  .title-lg {
    margin: 20px 0px 10px 0px;
    font-weight: 500;
    color: #636363;
    font-size: 17px;
  }

  &.primary {
    >.icon {
      background: #edfffd;
      color: #04bebe;
    }
  }

  &.success {
    >.icon {
      background: #f0f6ec;
      color: #94bb76;
    }
  }

  &.info {
    >.icon {
      background: #f1eef6;
      color: #603d8d;
    }
  }

  .favourite {
    position: absolute;
    top: 10px;
    right: 0px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}