.archivos {
  .td-icon {
    display: flex;
    align-items: center;

    .icon {
      margin-right: 20px;
      max-width: 30px;
    }
  }

  .actions {
    text-align: center;
    padding: 30px;
  }
}
