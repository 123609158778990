.legajos-form {
  .legajo-header {
    background-image: url("./../../../assets/images/legajo.jpg");
    border-radius: 10px 10px 0 0;

    >.content {
      display: flex;
      flex-direction: row;
      padding: 20px;
      border-radius: 10px 10px 0 0;

      >.foto {
        margin: 0 20px;
        padding: 5px;
        min-height: 150px;
        min-width: 150px;
        height: 150px;
        width: 150px;
        border-radius: 100px;
        background-color: #fff;
        box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.1);

        &.editable {
          &:hover {
            >.preview {
              .overlay {
                opacity: 1;
                cursor: pointer;
              }
            }
          }
        }

        >.preview {
          border-radius: 100px;
          height: 100%;
          width: 100%;
          background-position: center center;
          background-repeat: no-repeat;
          background-size: cover;

          >.overlay {
            height: 100%;
            width: 100%;
            border-radius: 100px;
            background-color: rgba(0, 0, 0, 0.3);
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            color: #fff;
            font-size: 25px;
            opacity: 0;
            transition: all 0.3s ease-in-out;
          }
        }
      }

      >.data {
        padding: 10px 20px;
        width: 100%;

        .legajo {
          font-size: 13px;
          color: #666;
          margin-top: 15px;
        }

        .nombre {
          margin-bottom: 5px;
        }

        .search {
          .ant-btn {
            padding: 11px;
            min-width: initial;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
          }
        }

        .actions {
          margin-top: 40px;
          text-align: right;

          >.ant-btn {
            padding: 11px;
            min-width: initial;
            margin-left: 10px;
            min-width: 90px;

            >svg {
              margin-right: 5px;
            }
          }
        }
      }
    }

    >.menu {
      display: flex;
      flex-direction: row;
      border-bottom: 1px solid #d9d9d9;
      background: linear-gradient(118deg, #04bebe, #04bebe) !important;
      padding-top: 0 !important;

      >.item {
        width: 25%;
        font-weight: 500;
        color: #fff;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        transition: all 0.3s ease-in-out;
        border-bottom: 2px solid transparent;
        padding: 7px 15px;
        cursor: pointer;

        &:hover,
        &.active {
          background-color: rgba(0, 0, 0, 0.2);
        }

        &.disabled {
          background-color: #bbb !important;
          cursor: not-allowed;
        }
      }
    }
  }
}