.orgchart>ul>li>ul li::before {
  border-top-color: #ccc;
}

.orgchart>ul>li>ul li .oc-node::before,
.orgchart ul li .oc-node:not(:only-child)::after {
  background-color: #ccc;
}

.orgchart {
  .oc-node {

    &:hover,
    &.selected {
      background-color: transparent !important;
    }

    .content {
      background-color: #fff;
      border: 1px solid #d9d9d9;
      border-radius: 10px;
      width: 250px;
      box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.1);
      transition: all 0.3s ease-in-out;
      cursor: pointer;

      &.primary {
        border-top: 6px solid #04bebe;

        &:hover {
          background-color: #edfffd;
        }
      }

      &.success {
        border-top: 6px solid #94bb76;

        &:hover {
          background-color: #f0f6ec;
        }
      }

      &.info {
        border-top: 6px solid #a58dc1;

        &:hover {
          background-color: #f6f3f8;
        }
      }

      .cargo {
        font-weight: 600;
        padding: 15px 10px;
        padding-bottom: 0px;
        border-radius: 5px 5px 0 0;
      }

      .codigo {
        font-size: 10px;
        padding-top: 0px;
        border-radius: 0 0 10px 10px;
        color: #aaa;
      }

      .empleados {
        font-weight: 400;
        padding: 15px 10px;
        padding-top: 5px;
      }
    }
  }
}