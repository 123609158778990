.icon {
    font-size: 2em;
    background: #f1eef6;
    color: #94bb76;
    width: 50px;
    min-width: 50px;
    height: 50px;
    border-radius: 100px;
    text-align: center;
    padding-top: 7px;
    margin-right: 15px;
    cursor: pointer;

    > img {
      max-height: 35px;
      margin-top: -13px;
    }
  }