.form-formulas {
  width: 100%;

  .formulas-sidebar {
    border-right: 1px solid #f0f0f0;
    min-height: 100%;

    .operadores {
      margin-bottom: 20px;
      padding-right: 20px;

      h4 {
        font-size: 10px;
      }
    }

    .operandos {
      padding-right: 20px;
      height: calc(100% - 180px);

      h4 {
        font-size: 10px;
      }

      .operandos-section {
        margin-bottom: 10px;
      }

      p {
        margin-bottom: 0px;
        font-size: 10px;
      }

      .search {
        margin-bottom: 10px;
      }

      .sidebar-scroll {
        height: 100%;
      }
    }

    .ant-tag {
      margin-bottom: 5px;
      font-weight: 500;
      cursor: pointer;

      &.aqua {
        background: #04bebe;
        color: #fff;
      }

      &.green {
        background: #94bb76;
        color: #fff;
      }

      &.purple {
        background: #603d8d;
        color: #fff;
      }
    }
  }

  .formulas-content {
    padding-left: 30px;
    min-height: 400px;

    .header-formulas {
      padding-bottom: 20px;
      margin-bottom: 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .ant-form-item {
        margin-bottom: 0;
      }

      .ant-radio-button-wrapper {
        min-width: 106px;
        text-align: center;
      }
    }

    .formulas-list {
      .formulas-item {
        border-bottom: 1px solid #e5e5e5;
        position: relative;
        padding-top: 10px;

        &:last-of-type {
          border-bottom: none !important;
        }

        .formulas-delete {
          height: 30px;
          width: 30px;
          border-radius: 100px;
          font-size: 15px;
          margin-top: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer !important;
          color: #888;

          &:hover {
            background-color: #f5f5f5;
          }
        }
      }
    }

    .formulas-add {
      text-align: center;
      cursor: pointer;
      font-weight: 500;
      padding: 10px;
      border-radius: 10px;
      color: #888;
      transition: all 0.3s ease-in-out;

      &:hover {
        background-color: #eaf7f5;
        color: #04bebe;
      }
    }
  }
}