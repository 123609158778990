.ant-picker {
  width: 100%;
  border-radius: 5px;
  min-height: 38px;
}

.ant-picker:hover {
  border-color: #d9d9d9;
}

.ant-picker-focused {
  border-color: #04bebe !important;
  -webkit-box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
}

.ant-picker-date-panel .ant-picker-month-btn,
.ant-picker-date-panel .ant-picker-content thead tr th,
.ant-picker-cell .ant-picker-cell-inner {
  text-transform: capitalize !important;
}

.ant-picker-today-btn {
  color: #04bebe;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border: 1px solid #04bebe;
}

.ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner,
.ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end) .ant-picker-cell-inner {
  background: #04bebe;
  color: #fff;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  background: #04bebe;
}