.legajos-form {
  >.form {
    padding: 0px;
    margin-top: 0px;

    .loading {
      width: 100%;
      min-height: calc(100vh - 200px);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: #999;
      padding-bottom: 80px;

      >svg {
        margin: 0 auto 20px auto;
      }
    }

    .section {
      margin-top: 0;

      .section-header {
        padding: 30px 40px 0px 30px;

        .section-title {
          font-size: 20px;
          font-weight: 500;

          svg {
            margin-left: 10px;
          }
        }

        .section-subtitle {
          font-size: 10px;
          font-style: italic;
          color: #888;
        }
      }

      .section-sidebar {
        .section-menu {
          >.menu-item {
            padding: 10px 30px;
            transition: all 0.3s ease-in-out;
            cursor: pointer;

            &:hover,
            &.active {
              background-color: #fafafa;
              font-weight: 500;
              border-right: 3px solid #04bebe;
            }
          }
        }
      }

      .section-form {
        padding: 0px 30px;
      }

      .section-table {
        margin-bottom: 30px;
      }

      .subsection-form {
        padding: 30px;
        padding-bottom: 0px;
      }

      .subsection-table {
        margin-top: 20px;

        .table-empty {
          background-color: #fafafa;
          text-align: center;
          font-size: 13px;
        }
      }

      .subsection-loading {
        width: 100%;
        min-height: 300px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: #999;

        >.ant-spin {
          margin-bottom: 15px;
        }
      }
    }

    .reingreso {
      margin-top: 24px;
      border: 1px solid #d9d9d9;
      border-radius: 5px;
      text-align: center;
      padding: 9px 0px 5px 0;
      cursor: pointer;
    }
  }

  .table-asignaciones {
    tr {

      >td,
      th {
        &:first-of-type {
          width: 120px;
          padding-left: 30px;
          text-align: center;
        }

        &:last-of-type {
          text-align: center;
        }
      }
    }
  }
}