.ant-modal {
  .ant-modal-header {
    border-radius: 5px 5px 0 0;
  }

  .ant-modal-content {
    border-radius: 5px;

    .ant-btn {
      min-width: initial;
    }

    .form-actions {
      padding: 0px !important;
    }
  }

  .ant-modal-footer {
    text-align: center;
  }
}

.ant-modal-confirm {
  width: 500px !important;
}

.ant-modal-mask,
.ant-image-preview-mask {
  background-color: rgba(0, 0, 0, 0.6);
}

.modal-actions {
  text-align: center;

  > * {
    margin: 10px 5px 0 5px;
  }
}
