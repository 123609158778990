.ant-btn {
  line-height: 1;
  padding: 0.9rem 2rem;
  border-radius: 0.4285rem;
  height: auto;
  min-width: 150px;
}

.ant-btn-primary {
  color: #fff;
  border: none !important;
  background: #04bebe !important;
  transition: box-shadow 0.35s ease;

  &:hover {
    box-shadow: 0 0 10px 1px rgba(53, 139, 128, 0.7) !important;
  }
}

.ant-btn-primary[disabled],
.ant-btn-primary[disabled]:hover,
.ant-btn-primary[disabled]:focus,
.ant-btn-primary[disabled]:active {
  color: #fff;
  background: #04bebe !important;
  box-shadow: none !important;
}