.ant-pagination-item {
  border-radius: 100px;

  &:focus,
  &:hover {
    border-color: #04bebe;

    a {
      color: #04bebe !important;
    }
  }
}

.ant-pagination-item-active {
  font-weight: 500;
  background: linear-gradient(118deg, #04bebe, #04bebe);
  box-shadow: 0 0 10px 1px rgba(53, 139, 128, 0.7) !important;
  border: none;

  a {
    color: #fff !important;
  }

  &:hover,
  &:focus {
    a {
      color: #fff !important;
    }
  }
}

.ant-pagination-prev,
.ant-pagination-next {

  &:focus,
  &:hover {
    .ant-pagination-item-link {
      color: #603d8d;
      border-color: #603d8d;
    }
  }

  .ant-pagination-item-link {
    border-radius: 100px;
    position: relative;

    .anticon {
      position: absolute;
      top: 9px;
      left: 9px;
    }
  }
}