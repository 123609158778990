.historial-content {
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
  .ant-select-multiple .ant-select-selector {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .ant-input,
  .ant-input-affix-wrapper {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.historial-trigger {
  width: 40px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #d9d9d9;
  border-radius: 0 5px 5px 0;
  border-left: none;
  cursor: pointer;
  margin-top: 23px;

  &:hover {
    background-color: #f5f5f5;
  }
}

.modal-historial {
  .ant-modal-body {
    padding: 0px;
    padding-bottom: 20px;
  }
}
