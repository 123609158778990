.not-found {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;

  > img {
    width: 250px;
    max-width: 40%;
    margin-bottom: 20px;
  }
}
