.form {
  padding: 30px;
  padding-bottom: 0;
  margin-top: 70px;

  &.px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .form-icon {
    font-size: 5em;
    background: #eaf7f5;
    color: #04bebe;
    text-align: center;
    height: 120px;
    width: 120px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    margin-top: -90px;
    margin-bottom: 20px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16),
      0 2px 10px 0 rgba(0, 0, 0, 0.12);

    img {
      max-height: 80px;
      margin-top: -10px;
    }
  }

  .form-actions {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 10px 0 30px 0 !important;

    >* {
      margin: 7px;
    }
  }
}

.section {
  width: 100%;
  margin-top: 30px;

  >.section-header {
    padding: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;

    >.icon {
      font-size: 2em;
      background: #edfffd;
      color: #04bebe;
      width: 50px;
      min-width: 50px;
      height: 50px;
      border-radius: 100px;
      text-align: center;
      padding-top: 7px;
      margin-right: 15px;

      >img {
        max-height: 35px;
        margin-top: -13px;
      }
    }

    >.content {
      >.title {
        font-weight: 500;
        font-size: 15px;
      }

      >.subtitle {
        font-size: 14px;
        color: #888;
      }
    }

    >.collapse {
      margin-left: auto;
      height: 30px;
      width: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100px;

      &:hover {
        background-color: #fafafa;
      }
    }
  }

  >.react-slidedown {
    >.section-content {
      border-top: 1px solid #efefef;

      >.section-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;
        padding: 20px 30px;
        border-bottom: 1px solid #efefef;

        &:hover {
          background-color: #fafafa;
        }

        .title {
          font-weight: 500;
          margin-bottom: 3px;
        }

        .subtitle {
          color: #888;
          font-size: 10px;
        }

        >.right {
          margin-left: auto;
          text-align: right;
        }
      }

      .actions {
        padding: 20px;
        text-align: center;

        >button {
          margin: 0 auto;
        }
      }
    }
  }
}

.ant-modal-body {
  .form {
    padding: 0px;
    margin: 0px;
  }
}

hr {
  background-color: #f5f5f5;
  height: 1px;
  border: 0;
}

.form-section {
  padding: 20px 40px;

  &.table-section {
    padding-left: 0px !important;
    padding-right: 0px !important;

    .actions {
      padding: 10px 40px;
    }
  }

  .actions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px 0;
  }
}

.historial {
  display: block;
  margin-top: -20px;
  cursor: pointer;
  font-size: 11px;
  color: #999;
  width: 100px;
}