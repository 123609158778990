.ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
.ant-select-multiple .ant-select-selector {
  border-radius: 5px;
  min-height: 38px;
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: #d9d9d9;
}

.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
.ant-select-focused:not(.ant-select-disabled).ant-select-multiple .ant-select-selector {
  border-color: #04bebe;
  -webkit-box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  padding-top: 3px;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: #edfffd;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) .ant-select-item-option-state {
  color: #04bebe;
}

.ant-select-multiple .ant-select-selection-item {
  background-color: #edfffd;
  padding: 2px 20px;
  height: 30px;
  color: #04bebe;
  font-weight: 500;
}

.ant-select-disabled.ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
.ant-select-disabled.ant-select-multiple .ant-select-selector {
  color: #000;
  background-color: #fff;
  cursor: initial;
}

.ant-select-disabled.ant-select-multiple .ant-select-selection-item {
  cursor: initial;
  background-color: #edfffd;
  color: #04bebe;
}

.ant-select-item-option-content {
  font-size: 13px;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
  margin-top: 3px;
}

.ant-picker.ant-picker-disabled {
  background: transparent;
  cursor: default !important;
}

.ant-picker-input>input[disabled] {
  color: #000;
  cursor: default !important;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 5px;
  min-height: 38px;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-color: #04bebe;
  -webkit-box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
}