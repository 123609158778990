@import "~antd/dist/antd.css";

html,
body,
#root {
  height: 100vh;
  max-height: 100vh;
  margin: 0 !important;
  font-family: "Montserrat", sans-serif;

  > .switch-wrapper {
    position: relative;
    width: 100%;
    height: 100vh;

    > div {
      position: absolute;
      width: 100%;
      height: 100vh;
    }
  }
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.cursor-pointer {
  cursor: pointer;
}