.stepper {
  padding: 21px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid rgba(34, 41, 47, 0.08);

  >.step {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;

    &.active {
      >.icon {
        background: linear-gradient(118deg, #04bebe, #04bebe) !important;
        box-shadow: 0 3px 6px 0 rgba(53, 139, 128, 0.4) !important;
        color: #fff;
      }

      .title {
        color: #04bebe;
      }
    }

    &.completed {
      >.icon {
        background-color: #eaf7f5;
        color: #04bebe !important;
      }

      .title {
        color: #b9b9c3;
      }
    }

    >.icon {
      background-color: rgba(186, 191, 199, 0.12);
      color: #babfc7;
      height: 40px;
      width: 40px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
      font-size: 18px;
      margin-right: 15px;
    }

    .title {
      color: #6e6b7b;
      font-weight: 600;
      margin-bottom: 0px;
    }

    .description {
      color: #b9b9c3;
      font-weight: 400;
      font-size: 10px;
    }
  }

  >.separator {
    margin: 0 20px;
    font-size: 20px;
    color: #b9b9c3;
    padding-top: 10px;
  }
}