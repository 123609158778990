.liquidacion {
  .loading {
    width: 100%;
    min-height: calc(100vh - 180px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #999;
    padding-bottom: 80px;

    > svg {
      margin: 0 auto 30px auto;
    }
  }

  .table-footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px 40px 20px 40px;
    min-height: 45px;
  }
}
