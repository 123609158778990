.finalidadFuncion {
  .row {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px 25px;

    &:nth-child(even) {
      background-color: #f5f5f5;
    }
  }

  .grupo {
    border-bottom: 2px solid #eee;
    margin-bottom: 40px;

    .grupo-header {
      font-weight: 600;
      font-size: 14px;
      padding-top: 10px;
      padding-bottom: 10px;
      text-transform: uppercase;
    }

    .grupo-name {
      font-weight: 600;
      padding: 15px 25px;
      font-size: 17px;
      text-transform: uppercase;
    }
  }

  .convenio {
    border-top: 1px solid #eee;
    padding-bottom: 40px;

    .convenio-name {
      width: 30%;
      font-size: 14px;
    }

    .concepto {
      width: calc(100% - 30% - 200px);
    }

    .importe {
      width: 200px;
      text-align: right;
    }
  }

  .total-title {
    font-weight: 600;
    text-transform: uppercase;
  }

  .total-value {
    font-weight: 600;
  }

  .total-title-lg {
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
  }

  .total-value-lg {
    font-size: 18px;
    font-weight: 600;
  }
}
