.header {
  margin: 30px 20px;

  > .header-breadcrumb {
    > .ant-breadcrumb {
      display: flex;
      font-size: 13px;

      > span {
        display: flex;

        &:last-of-type {
          .ant-breadcrumb-separator {
            display: none;
          }
        }

        .ant-breadcrumb-link,
        .ant-breadcrumb-separator {
          display: flex;
          align-items: center;
          font-weight: 400;
          color: #999;

          a {
            color: #888 !important;

            &:hover {
              color: #000 !important;
            }
          }
        }
      }
    }
  }

  > .header-info {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    > .header-title {
      font-weight: 500;
      font-size: 1.5rem;
      color: #636363;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 5px;

      .ant-form-item {
        margin-bottom: 3px !important;
        margin-left: 10px;
      }
    }

    > .header-actions {
      display: flex;
      flex-direction: row;

      > .ant-btn {
        padding: 0px;
        min-width: 110px;
        height: 40px;
        border-radius: 10px;
        margin-left: 10px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        > svg {
          margin-right: 5px;
        }
      }
    }
  }
}

@media only screen and (max-width: 550px) {
  .header {
    > .header-info {
      > .header-actions {
        > .ant-btn {
          min-width: 50px;

          > svg {
            margin-right: 0px;
          }

          > span {
            display: none;
          }
        }
      }
    }
  }
}
.favorito-header {
  .ant-rate-star-first,
  .ant-rate-star-second {
    color: #d8d8d8;
    transition: all 0.3s;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .ant-rate-star-full .ant-rate-star-second,
  .ant-rate-star-half .ant-rate-star-first {
    color: inherit;
  }
}
