.menu {
  padding-top: 30px;

  .section-header {
    cursor: pointer;

    .ant-row {
      overflow: hidden;
    }
  }

  .ant-divider-horizontal.ant-divider-with-text-left::before {
    width: 3%;
  }
  .ant-divider-inner-text {
    font-size: 20px;
    color: #666;
  }
}
