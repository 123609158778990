.ant-input,
.ant-input-affix-wrapper {
  border-radius: 5px;
  padding: 7px 10px;
}

.ant-input:hover,
.ant-input-affix-wrapper:hover {
  border-color: #d9d9d9;
}

.ant-input:focus,
.ant-input-focused,
.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  border-color: #04bebe !important;
  -webkit-box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
}

.ant-form-item-has-error .ant-input,
.ant-form-item-has-error .ant-input-affix-wrapper,
.ant-form-item-has-error .ant-input:hover,
.ant-form-item-has-error .ant-input-affix-wrapper:hover {
  border-color: #f00 !important;
}

.ant-form-item-has-error .ant-input:focus,
.ant-form-item-has-error .ant-input-affix-wrapper:focus,
.ant-form-item-has-error .ant-input-focused,
.ant-form-item-has-error .ant-input-affix-wrapper-focused {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(234, 84, 85, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(234, 84, 85, 0.25);
}

.ant-form-item-explain-error {
  color: #f00;
  text-align: left !important;
  font-size: 0.7rem !important;
  margin-top: 2px;
}

.ant-input[disabled],
.ant-input-affix-wrapper-disabled {
  color: #000;
  background-color: #fff;
  cursor: initial;
}

.disabled {
  color: #000;
  background-color: #f5f5f5 !important;
  cursor: initial;
}

.textarea {
  margin-bottom: 0px;
}

.ant-input-search .ant-input:hover,
.ant-input-search .ant-input:focus {
  border-color: #d9d9d9;
}

input[type="file"] {
  display: none !important;
}

.ant-form-item-extra {
  font-size: 10px;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #04bebe;
  border-color: #04bebe;
}

.ant-checkbox-checked::after {
  border: 1px solid #04bebe;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus+.ant-checkbox-inner {
  border-color: #04bebe;
}