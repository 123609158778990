.ant-picker-calendar {
  tbody {
    > tr {
      background-color: transparent !important;

      &:first-of-type {
        border-top: none !important;
      }

      > td {
        background-color: #fff !important;
        transition: all 0.3s ease-in-out;
      }
    }
  }

  .calendar-day {
    font-weight: 600;
    min-height: 130px;
    padding: 0 10px;
    margin: 0 5px;
    border-top: 2px solid #f0f0f0;

    &.weekend {
      color: #bfbfbf;
      background-color: #f5f5f5;
      border-color: #bfbfbf;
    }

    &.enfermedad {
      color: #ff9f43;
      background-color: #fff3e8;
      border-color: #ff9f43;
    }

    &.accidente {
      color: #ea5455;
      background-color: #fceaea;
      border-color: #ea5455;
    }

    &.especial {
      color: #00cfe8;
      background-color: #e0f9fc;
      border-color: #00cfe8;
    }

    &.vacaciones {
      color: #7367f0;
      background-color: #eeedfd;
      border-color: #7367f0;
    }

    &.asistencia {
      color: #28c773;
      background-color: #e5f8ed;
      border-color: #28c773;
    }

    &.inasistencia {
      color: #bfbfbf;
      background-color: #fff;
      border-color: #bfbfbf;
    }

    .badge {
      text-align: center;
      padding-top: 30px;
    }
  }
}
