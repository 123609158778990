.acumulado {
  .anio {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .navigation {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px;
      border-radius: 100px;
      cursor: pointer;
      margin: 0 20px;

      &:hover {
        background-color: #efefef;
      }
    }
  }

  .total {
    font-size: 15px !important;
    font-weight: 600;
  }
}
