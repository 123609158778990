.table-clases {
  .clases-period {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #efefef;
    padding: 10px 30px;
    margin: 0px;

    > .prev,
    > .next {
      cursor: pointer;
      border-radius: 100px;
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        background-color: #f5f5f5;
      }

      &.disabled {
        opacity: 0 !important;
        cursor: initial !important;
      }
    }
  }

  tr {
    th,
    td {
      text-align: center;
    }
  }
}
